// Primary — Brand colors
export const purple     = '#7f5edf'
export const lilac      = '#cab1f2'
export const beige      = '#fcfbf2'
export const coolGray   = '#f5f4fa'
export const navy       = '#242142'

// Secondary — Context classes
export const coral      = '#fe8968'
export const blue       = '#7fc8e9'
export const green      = '#19a359'
export const yellow     = '#ffcf00'

// Tertiary — Only used for fundraiser customization and other odd things
export const teal       = '#248485'
export const pink       = '#f0b4d4'
export const orange     = '#ff880b'
export const brown      = '#946635'

// Bootstrap mappings
export const primary    = purple
export const secondary  = pink
export const success    = green
export const info       = blue
export const warning    = yellow
export const danger     = coral
export const bright     = beige
export const light      = coolGray
export const dark       = navy

const COLORS = {
  purple, lilac, beige, coolGray, navy,
  coral, blue, green, yellow,
  teal, pink, orange, brown,
  primary, secondary, success, info, warning, danger, bright, light, dark,
}

export default COLORS
