const EnvVars = require('../services/dotenv/variables');

/* global site, edition*/
/* eslint no-undef: "error"*/

function getActiveModules() {
  const actives = [];
  const idToFlagMap = {};

  for (const [id, flag] of Object.entries(idToFlagMap)) {
    if (EnvVars.get(`TRINITY_CONFIGURATION.${edition}.michonne.features.${flag}`)) {
      actives.push(id);
    }
  }
  return actives;
}

function getTrinityOverrides(options) {
  const siteType = site === 'business' ? 'business' : 'default';
  const overrides = EnvVars.get(
    `TRINITY_CONFIGURATION.${edition}.michonne.ads.adfuelOptionsOverrides`
  );

  if (overrides && overrides[siteType]) {
    return {
      ...options,
      ...overrides
    };
  } else {
    return options;
  }
}

let options = {};

const isEdition = edition === 'international';

if (site === 'espanol') {
  options = EnvVars.get(`ADFUEL_CONFIGS.${site}`);
} else if (site === 'business') {
  options = EnvVars.get(`ADFUEL_CONFIGS.${site}-${edition}`);
} else {
  options = EnvVars.get(`ADFUEL_CONFIGS.${edition}`);
}
window.AdFuelOptions = getTrinityOverrides(options);
// Enumerates active modules to resolve timing issue
window.AdFuelOptions.ADFUEL.ACTIVE_MODULES = getActiveModules();

if (EnvVars.get(`TRINITY_CONFIGURATION.${edition}.michonne.features.enableIndexExchange`)) {
  require('@cnnprivate/digital-ads-indexexchangeidentity/dist/ixIdentity.min.js');
}

if (EnvVars.get(`TRINITY_CONFIGURATION.${edition}.michonne.features.enablePrebid`)) {
  require('@cnnprivate/digital-ads-prebid/dist/prebidBundle.min.js');
}

if (EnvVars.get(`TRINITY_CONFIGURATION.${edition}.michonne.features.enableAmazonDisplayAds`)) {
  require('@cnnprivate/digital-ads-a9/dist/A9.min.js');
}

if (EnvVars.get(`TRINITY_CONFIGURATION.${edition}.michonne.features.enableProximic`)) {
  require('@cnnprivate/digital-ads-proximic/dist/Proximic.min.js');
}

if (isEdition && EnvVars.get('ENABLE_PERMUTIVE')) {
  require('@cnnprivate/digital-ads-permutive/dist/Permutive.min.js');
}

if (EnvVars.get(`TRINITY_CONFIGURATION.${edition}.michonne.features.enableIntegralAdScience`)) {
  require('@cnnprivate/digital-ads-ias/dist/IAS.min.js');
}

if (EnvVars.get(`TRINITY_CONFIGURATION.${edition}.michonne.features.enableFreewheelProgrammatic`)) {
  require('@cnnprivate/digital-ads-fwprogrammatic/dist/FWProgrammatic.min.js');
}

require('@cnnprivate/digital-ads-guid/dist/Guid.min.js');

require('@cnnprivate/digital-ads-transactionid/dist/TransId.min.js');

require('@cnnprivate/digital-ads-aui/dist/Audience.min.js');
