import addUnit  from 'src/utils/addUnit'
import sliceObj from 'src/utils/sliceObj'
import { primary } from 'src/data/colors'

const toSnakeCase = s => s.replace(/([A-Z])/g, (m, l) => `_${l.toLowerCase()}`)
const toKebabCase = s => s.replace(/([A-Z])/g, (m, l) => `-${l.toLowerCase()}`)
const metadataKey = s => `data-${toKebabCase(s)}`

const extractMetadata = obj =>
  sliceObj(obj, Object.keys(obj).filter(key => /^x[A-Z]/.test(key)))

const serialize = (obj, keyTransform) =>
  Object.keys(obj).filter(key => obj[key]).map(key => [
    encodeURIComponent(keyTransform(key)),
    encodeURIComponent(obj[key])
  ].join('=')).join('&')

const getParams = (dataset, config) => ({
  ...sliceObj(dataset, config.params),
  ...config.standardParams,
  embeddedOn: location.href || 1,
})

const mount = (parent, child) => {
  while (parent.firstChild)
    parent.removeChild(parent.firstChild)
  parent.appendChild(child)
}

const createLink = (element, config, href) => {
  const link = document.createElement('a')
  link.setAttribute('href', href)
  link.setAttribute('target', '_blank')
  link.textContent = config.httpsOnly.content
  link.style.display = 'inline-block'
  link.style.backgroundColor = primary
  link.style.color = '#fff'
  link.style.borderRadius = '0.2em'
  link.style.padding = '0.5em 0.75em'
  link.style.fontWeight = 'bold'
  link.style.textDecoration = 'none'
  mount(element, link)
}

const createWidget = (element, config, origin) => {
  const dataset = element.dataset

  if (!config.isValid(dataset)) {
    console.error('Invalid widget config')
    return false
  }

  const path      = [ config.path, config.identifier(dataset) ].join('/')
  const params    = getParams(dataset, config)
  const metadata  = serialize(extractMetadata(dataset), metadataKey)
  const pledge_to = new URLSearchParams(location.search).get('pledge_to')
  const query     = serialize({ ...params, metadata, pledge_to }, toSnakeCase)
  const src       = origin + [ path, query ].join('?')

  if (config.httpsOnly && location.protocol != 'https:') {
    console.warn(config.httpsOnly.message)
    createLink(element, config, src)
    return false
  }

  const iframe = document.createElement('iframe')
  iframe.className = `${config.className}-iframe ${dataset.iframeClass || ''}`
  iframe.src = src
  iframe.style.width    = addUnit(dataset.width || '100%')
  iframe.style.height   = addUnit(config.height(dataset, element))
  iframe.style.border   = 0
  iframe.style.display  = 'block'

  if (config.iframeOptions)
    Object.keys(config.iframeOptions).forEach(key =>
      iframe[key] = config.iframeOptions[key])

  if (config.iframeStyle)
    Object.keys(config.iframeStyle).forEach(key =>
      iframe.style[key] = config.iframeStyle[key])

  mount(element, iframe)

  if (config.init)
    config.init({ dataset, element, iframe, origin })

  return {
    iframe,
    autoHeight: config.autoHeight(dataset, element),
    intersection: config.intersection,
  }
}

export default createWidget
