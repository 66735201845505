const EnvVars = require('../services/dotenv/variables');

/* global site, edition*/
/* eslint no-undef: "error"*/

function getActiveModules() {
  const actives = [];
  const idToFlagMap = {};

  for (const [id, flag] of Object.entries(idToFlagMap)) {
    if (EnvVars.get(`TRINITY_CONFIGURATION.${edition}.michonne.features.${flag}`)) {
      actives.push(id);
    }
  }
  return actives;
}

function loadAdFuelHead() {
  if (EnvVars.get(`TRINITY_CONFIGURATION.${edition}.michonne.features.enableIntegralAdScience`)) {
    require('@cnnprivate/digital-ads-ias/dist/IAS.min.js');
  }

  if (
    EnvVars.get(`TRINITY_CONFIGURATION.${edition}.michonne.features.enableFreewheelProgrammatic`)
  ) {
    require('@cnnprivate/digital-ads-fwprogrammatic/dist/FWProgrammatic.min.js');
  }

  require('@cnnprivate/digital-ads-guid/dist/Guid.min.js');

  require('@cnnprivate/digital-ads-transactionid/dist/TransId.min.js');

  require('@cnnprivate/digital-ads-aui/dist/Audience.min.js');
}

function loadAdFuelBody() {
  if (EnvVars.get(`TRINITY_CONFIGURATION.${edition}.michonne.features.enableCep`)) {
    require('@cnnprivate/digital-ads-cep/dist/Cep.min.js');
  }

  if (EnvVars.get(`TRINITY_CONFIGURATION.${edition}.michonne.features.enableInViewRefresh`)) {
    require('@cnnprivate/digital-ads-inviewrefresh/dist/InViewRefresh.min.js');
  }

  if (
    EnvVars.get(`TRINITY_CONFIGURATION.${edition}.michonne.features.enableMalvertisingDetection`)
  ) {
    require('@cnnprivate/digital-ads-malvertisingdetection/dist/MalvertisingDetection.min.js');
  }

  if (EnvVars.get(`TRINITY_CONFIGURATION.${edition}.michonne.features.enableBlockThrough`)) {
    require('@cnnprivate/digital-ads-blockthrough/dist/Blockthrough.min.js');
  }

  require('@cnnprivate/digital-ads-adfuel/dist/adfuel.min.js');
}

function getTrinityOverrides(options) {
  const siteType = site === 'business' ? 'business' : 'default';
  const overrides = EnvVars.get(
    `TRINITY_CONFIGURATION.${edition}.michonne.ads.adfuelOptionsOverrides`
  );

  if (overrides && overrides[siteType]) {
    return {
      ...options,
      ...overrides
    };
  } else {
    return options;
  }
}

let options = {};
if (site === 'espanol') {
  options = EnvVars.get(`ADFUEL_CONFIGS.${site}`);
} else if (site === 'business') {
  options = EnvVars.get(`ADFUEL_CONFIGS.${site}-${edition}`);
} else {
  options = EnvVars.get(`ADFUEL_CONFIGS.${edition}`);
}
window.AdFuelOptions = getTrinityOverrides(options);
// Enumerates active modules to resolve timing issue
window.AdFuelOptions.ADFUEL.ACTIVE_MODULES = getActiveModules();

loadAdFuelHead();

(function () {
  if (window.AdFuelOptions) {
    loadAdFuelBody();
  }
})();
