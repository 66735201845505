const Donate = {
  className: 'plg-donate',
  path: '/widgets/donate',
  params: [
    'amount', 'tipRate', 'firstName', 'lastName', 'email',
    'locale', 'currency', 'subscription', 'honoreeName',
    'styleBrandColor', 'styleSeamless',
    'styleHideHeader', 'styleHideFooter', 'styleHideSocial',
    'template', 'enableSession', 'donationTags',
    'disableHeaderLink', 'paymentMethod',
    'delegateModals', 'removeProfileLink',
  ],

  iframeOptions: {
    allow: 'payment',
    allowPaymentRequest: true,
    title: 'Donation Form',
  },

  intersection: true,
  httpsOnly: {
    message: 'Pledge donaton forms can only be embedded on HTTPS pages',
    content: 'Donate via Pledge',
  },

  identifier (dataset) {
    return dataset.widgetId || [
      dataset.partnerKey,
      dataset.itemId || dataset.externalId,
      dataset.ein && [ 'ein', dataset.ein ].join('/'),
    ].filter(Boolean).join('/')
  },

  height (dataset) {
    if (dataset.height && dataset.height != 'auto')
      return dataset.height

    let initialHeight = 374
    if (dataset.styleHideHeader) initialHeight = initialHeight - 138
    if (dataset.styleHideFooter) initialHeight = initialHeight - 56
    return initialHeight.toString()
  },

  autoHeight (dataset) {
    return !dataset.height || dataset.height === 'auto'
  },

  isValid (dataset) {
    return dataset.widgetId ||
      dataset.partnerKey && (dataset.itemId || dataset.externalId || dataset.ein)
  },
}

export default Donate
